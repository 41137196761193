<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row>
        <v-col
          cols="12"
          class="mt-2 px-6 d-flex"
          :class="{'justify-space-between': $vuetify.breakpoint.smAndUp, 'flex-column': $vuetify.breakpoint.smAndDown}"
        >
          <div class="d-flex flex-wrap align-center">
            <div class="mt-3" :class="{'flex-grow-1': $vuetify.breakpoint.smAndDown}">
              <consultar-rango-fecha @consultar="solicitarHistoria" />
            </div>
            <div class="mt-3" :class="{'flex-grow-0': $vuetify.breakpoint.xsOnly}">
              <v-btn
                color="icono"
                class="ml-2"
                outlined
                small
                @click="getHistVisitas"
                :disabled="loadHistVisita"
              >
              <v-icon size="22" :left="!$vuetify.breakpoint.smAndDown">mdi-update</v-icon>
              <span v-if="!$vuetify.breakpoint.smAndDown">Actualizar</span>
              </v-btn>
              <v-btn
                v-if="$vuetify.breakpoint.smAndUp"
                color="icono"
                class="mx-2"
                outlined
                small
                @click="leyenda = !leyenda"
                >
                <v-fade-transition>
                  <v-icon
                  size="22"
                  left
                  v-text="leyenda ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down'"
                  />
                </v-fade-transition>
                Leyenda
              </v-btn>
            </div>
          </div>
          <div class="d-flex flex-wrap align-center" :class="{'mt-2': $vuetify.breakpoint.smAndDown}">
            <div class="mt-2">
              <btn-pedido v-model="filtrar.pedido_visita" />
            </div>
            <div class="mt-2">
              <btn-geolocalizacion v-model="filtrar.geo" />
            </div>
            <div class="mt-2">
              <btn-imagen v-model="filtrar.imagen" />
            </div>

          </div>
        </v-col>
      </v-row>
      <v-row class="mt-1">
        <v-col cols="12" class="px-6">
          <search-expand
          v-model="search"
          :load="loadHistVisita"
          placeholder="Buscar visita"
          />
        </v-col>
        <leyenda v-model="leyenda" />
      </v-row>
      <v-row v-if="!loadHistVisita">
        <v-col cols="12">
        <v-data-iterator
          disable-pagination
          hide-default-footer
          :search="search"
          :items="itemsHV"
          :items-per-page="10"
          :footer-props="{itemsPerPageText: 'Mostrar'}"
          :sort-by="sortBy"
          :sort-desc="orderSort"
          :class="{'light-blue-color': $vuetify.breakpoint.xsOnly}"
        >
          <template v-slot:header>
            <v-list-item class="grey lighten-5">
              <v-list-item-content>
                <v-row>
                  <v-col cols="5" class="d-flex align-center">
                    <a href="" class="text-decoration-none" @click.prevent="activeSort('cli_des')">
                      <span
                        class="blue-grey--text font-weight-regular text--lighten-2"
                        :class="{'font-weight-bold': columnActived('cli_des') }"
                      >
                        Cliente
                      </span>
                      <template v-if="columnActived('cli_des')">
                        <v-fade-transition>
                          <v-icon size="18" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
                        </v-fade-transition>
                      </template>
                      <v-icon v-else size="18" color="blue-grey lighten-3">mdi-menu-swap</v-icon>
                    </a>
                  </v-col>
                  <template v-if="$vuetify.breakpoint.smAndUp">
                    <v-col cols="3" class="d-flex justify-center align-center">
                    <a href="" class="text-decoration-none" @click.prevent="activeSort('motivo_id')">
                        <span
                          class="blue-grey--text font-weight-regular text--lighten-2"
                          :class="{'font-weight-bold': columnActived('motivo_id') }"
                        >
                          Motivo
                        </span>
                        <template v-if="columnActived('motivo_id')">
                          <v-fade-transition>
                            <v-icon size="18" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
                          </v-fade-transition>
                        </template>
                        <v-icon v-else size="18" color="blue-grey lighten-3">mdi-menu-swap</v-icon>
                      </a>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-center align-center">
                      <span class="blue-grey--text text--lighten-2 font-weight-regular">Duración</span>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-center align-center">
                      <a href="" class="text-decoration-none" @click.prevent="activeSort('cod_visita_tipo')">
                        <span
                          class="blue-grey--text font-weight-regular text--lighten-2"
                          :class="{'font-weight-bold': columnActived('cod_visita_tipo') }"
                        >
                          Tipo
                        </span>
                        <template v-if="columnActived('cod_visita_tipo')">
                          <v-fade-transition>
                            <v-icon size="18" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
                          </v-fade-transition>
                        </template>
                        <v-icon v-else size="18" color="blue-grey lighten-3">mdi-menu-swap</v-icon>
                      </a>
                    </v-col>
                  </template>
                  <v-col v-else cols="7" class="d-flex align-center justify-end">
                    <v-btn
                      color="primary"
                      class="mx-2"
                      text
                      small
                      @click="leyenda = !leyenda"
                    >
                      <v-fade-transition>
                        <v-icon
                          size="22"
                          left
                          v-text="leyenda ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down'"
                        />
                      </v-fade-transition>
                      Leyenda
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-action v-if="$vuetify.breakpoint.smAndUp" class="d-flex align-center">
                <a href="" style="width:100px" class="text-decoration-none" @click.prevent="activeSort('fecha')">
                  <span
                    class="blue-grey--text font-weight-regular text--lighten-2"
                    :class="{'font-weight-bold': columnActived('fecha') }"
                  >
                    Fecha
                  </span>
                  <template v-if="columnActived('fecha')">
                    <v-fade-transition>
                      <v-icon size="18" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
                    </v-fade-transition>
                  </template>
                  <v-icon v-else size="18" color="blue-grey lighten-3">mdi-menu-swap</v-icon>
                </a>
              </v-list-item-action>
            </v-list-item>
            <v-divider :class="{'mb-3': $vuetify.breakpoint.xsOnly}" />
          </template>
          <template v-slot:default="{ items }">
            <v-list
              class="pt-0"
              :class="{'px-3 transparent': $vuetify.breakpoint.xsOnly}"
            >
              <template v-for="(visita,i) in items">
                <v-slide-y-transition
                  :key="'tp-'+ i"
                  group
                  class="py-0"
                >
                  <historia-visita :data_visita="visita" :key="'historyV-'+i" @click="activeDetails(visita)" />
                </v-slide-y-transition>
                <v-divider class="hidden-xs-only" v-if="i < items.length - 1" :key="'section-'+i" />
              </template>
            </v-list>
          </template>
          <template v-slot:no-results>
            <v-row>
              <v-col cols="12 my-4 d-flex justify-center">
                <span class="blue-grey--text font-italic">No se encontraron registros que coincidan</span>
              </v-col>
            </v-row>
          </template>
          <template v-slot:no-data>
            <v-row>
              <v-col cols="12 my-4 d-flex justify-center">
                <span class="blue-grey--text font-italic">No se encontraron registros disponible</span>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-skeleton-loader
            type="table-tbody"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment'
import { get } from 'vuex-pathify'
import { gmapApi } from 'gmap-vue'
export default {
  name: 'Historico',
  filters: {
    horaFormat(val) {
      return moment(val).format('hh:mm:ss a');
    }
  },
  components:{
    ConsultarRangoFecha: () => import(
      /* webpackChunkName: "consultar-rango-fecha" */
      '@/widgets/ConsultarRangoFecha.vue'
    ),
    HistoriaVisita: () => import(
      /* webpackChunkName: "historia-visita" */
      './HistoriaVisita.vue'
    ),
    BtnPedido: () => import(
      /* webpackChunkName: "btn-pedido" */
      './Filtros/BtnPedido.vue'
    ),
    BtnGeolocalizacion: () => import(
      /* webpackChunkName: "btn-geolocalizacion" */
      './Filtros/BtnGeolocalizacion.vue'
    ),
    BtnImagen: () => import(
      /* webpackChunkName: "btn-imagen" */
      './Filtros/BtnImagen.vue'
    ),
    BtnMas: () => import(
      /* webpackChunkName: "btn-mas" */
      './Filtros/BtnMas.vue'
    ),
    Leyenda: () => import(
      /* webpackChunkName: "leyenda" */
      './Leyenda.vue'
    ),
  },
  data: () => ({
    selected: '',
    search: '',
    view_details: false,
    item_details: {},
    lista: [],
    loadHistVisita: false,
    fecha_visita_ini: '',
    fecha_visita_fin: '',
    sortBy: '',
    orderSort: true,
    view_btn_list: [],
    filtrar:{
      imagen: '',
      pedido_visita: '',
      geo: '',
      motivo_id: [],
      cod_visita_tipo: [],
    },
    leyenda: false,
  }),
  computed: {
    googleApi: gmapApi,
    idv: get('route/params@cod'),
    itemsHV () {
      let data = [];
      data = this.lista.map((item) => {
        return {
          ...item,
          imagen: item.fotos.length > 0 ? '1' : '0',
          pedido_visita: item.pedido ? 'Con Pedido' : 'Sin Pedido',
          geo: item.lat_cliente !== null && item.lng_cliente !== null ? '1' : '0',
        }
      })

      const CustomFiltersKeys = Object.keys(this.filtrar)
      return data.filter((item) => {
        return CustomFiltersKeys.every(keyFiltro => {
          if(this.filtrar[keyFiltro].length === 0) {
            return true;
          }
          return typeof this.filtrar[keyFiltro] === 'object'
            ? this.filtrar[keyFiltro].includes(item[keyFiltro])
            : (this.filtrar[keyFiltro].toString()).toLowerCase().includes((item[keyFiltro].toString()).toLowerCase())
        })
      })
    },
  },
  created() {
    this.fecha_visita_ini = moment().startOf('week').day(1).format("YYYY-MM-DD");
    this.fecha_visita_fin = moment().format('YYYY-MM-DD');
    this.getHistVisitas();
  },
  methods:{
    async getHistVisitas() {
       this.loadHistVisita = true;
       this.lista = [];
      try {
        const visitas = await this.$api.get(`vendedores/${this.idv}/visitas?fecha_inicio=${this.fecha_visita_ini}&fecha_final=${this.fecha_visita_fin}`);
        this.lista = visitas.data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener el Historial de Visita del Vendedor.',
          'error'
        )
      }
      this.loadHistVisita = false;
    },
    activeDetails(item) {
      this.$emit('showVisita', item);
    },
    cerrarDetails(value) {
       this.view_details = value;
       this.selected= null;
    },
    updateHist() {
      this.$emit('update');
    },
    solicitarHistoria(fechas) {
      this.$emit('updateHistoria', fechas);
      this.fecha_visita_ini = fechas.ini;
      this.fecha_visita_fin = fechas.fin;
      this.getHistVisitas();
    },
    calcularDuracion(item) {
      let inicio = moment(item.fecha+' '+item.hora_inicio, "HH:mm:ss");
      let fin = moment(item.fecha+' '+item.hora_fin, "HH:mm:ss");

      return fin.diff(inicio, 'minutes') + ' min';
    },
    activeSort(column) {
      this.sortBy = column;
      this.orderSort = !this.orderSort;
    },
    columnActived(column) {
      return column === this.sortBy
    },
  },
}
</script>
<style lang="sass">
.efecto-expand
  -webkit-transition: 0.4
  transition: 0.4s
</style>
